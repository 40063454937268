import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 64px;

  .cta-container {
    display: flex;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  ${atMinWidth.md`
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  `}
`;
